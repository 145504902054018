import React from 'react';
import { List, Datagrid, TextField, NumberField, ArrayField, TextInput, Filter, Show, SimpleShowLayout, Pagination } from 'react-admin';
import ChangeStatusButton from './ChangeStatusButton';
import CancelButton from './CancelButton';
import CardActions from '@material-ui/core/CardActions';
import BackButton from './BackButton';
import { AlertField, DetailedAlertField } from './OrderAlert';
import DateTimeField from './DateTimeField';
import AddressField from './AddressField';
import StatusField from './StatusField';
import LinkField from './LinkField';
import ExternalLinkField from './ExternalLinkField';
import Tracking from './Tracking';
import { withStyles } from '@material-ui/core/styles';
import { styles }  from "./styles";

var moment = require('moment-timezone');

const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const orderRowStyle = (record, index) => ({
    backgroundColor: rowColor(record.alert && record.alert.type)
});

const rowColor = (alertType) => {
    
    if (!alertType) return "white";

    if (alertType === "info") return "#c3e6cb"

    if (alertType === "warning") return "#ffdf7e";

    if (alertType === "error") return "#ed969e";

    return "white"

}

const OrdersPagination = props => (
    <Pagination rowsPerPageOptions={[]} {...props} />
)

const OrdersFilters = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
)

export const OrderList = withStyles(styles.listView)(({classes, ...props}) => (
    <List title="Current Orders" {...props} perPage={1000} pagination={<OrdersPagination />} filters={<OrdersFilters />} sort={{field: "statusCode", order: "DESC"}} bulkActionButtons={false} exporter={false}>
        <Datagrid rowStyle={orderRowStyle} rowClick="show" classes={{headerCell: classes.header, row: classes.row}}>
        {/* <Datagrid rowClick="show" classes={{headerCell: classes.header, row: classes.row}}> */}
            <AlertField label="" />
            <TextField label="Name" source="userName" className={classes.boldCell}/>
            <TextField label="Phone" source="userPhone" />
            <DateTimeField label="Created" source="createdAt" format="H:mm" />
            <DateTimeField label="Deliver" source="deliverAt" format="H:mm" />
            <DateTimeField label="Updated" source="statusUpdatedAt" format="H:mm" />
            {/* <TextField source="status" label="Order Status" sortBy="statusCode" headerClassName={classes.boldHeader} className={classes.boldCell}/> */}
            <StatusField label="Status" source="status" sortBy="statusCode" headerClassName={classes.boldHeader} className={classes.boldCell}/>
            <ChangeStatusButton label="Action" />
        </Datagrid>
    </List>
));

const OrderTitle = (props) => {

    var formattedDate = moment(props.record.deliverAt).tz(TIMEZONE).format("DD/MM/YYYY, H:mm")
    
    if (props.record.asap) {
        formattedDate = "As soon as possible - Up to " + formattedDate;
    }

    return (
        <span>
            <BackButton {...props} />
            {props.record.userName + " - " + formattedDate}
        </span>
    )
}

const cardActionStyle = {
    display: "flex",
    justifyContent: "flex-end"
};

const OrderActions = (props) => {

    var hide_tracking = props.resource === "pastorders";

    return (
        <CardActions className="order-actions" style={cardActionStyle}>
            { hide_tracking ? null : (<Tracking record={props.data} />) }
            <ChangeStatusButton record={props.data} />
            <CancelButton basePath={props.basePath} record={props.data} />
        </CardActions>
    )
}

export const OrderShow = withStyles(styles.showView)(({classes, ...props}) => {

    return (
        <Show {...props} title={<OrderTitle basePath={props.basePath} resource={props.resource} />} actions={<OrderActions {...props} />} >
            <SimpleShowLayout className={classes.container}>
                <DetailedAlertField className="show-field" />
                <StatusField className="show-field-bold" label="Status" source="status" addLabel={true} />
                <TextField className="show-field" label="Name" source="userName" />
                <TextField className="show-field" label="Phone" source="userPhone" />
                <AddressField className="show-field" label="Address" source="userAddress" addLabel={true} />
                <ArrayField className="show-field" source="items" >
                    <Datagrid>
                        <TextField source="name" />
                        <NumberField source="quantity" />
                        <NumberField source="price" options={{style: "currency", currency: "EUR"}} />
                    </Datagrid>
                </ArrayField>
                <DateTimeField className="show-field" label="Deliver" source="deliverAt" format="DD/MM/YYYY, H:mm" addLabel={true} small={false} />
                <TextField className="show-field" label="VAT" source="vat" />
                <TextField className="show-field" label="Customer Notes" source="notes" />
                <TextField className="show-field" label="identifier" source="id" />
                <ExternalLinkField className="show-field" label="Payment Url" url={process.env.REACT_APP_STRIPE_PAYMENTS_URL} source="stripeChargeId" addLabel={true}/>
                <LinkField className="show-field" label="Ride" source="rideId" reference={"rides"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
})