import React from 'react';
import { Admin, Resource } from 'react-admin';
import { OrderList, OrderShow } from './orders';
import { PastOrderList } from './pastOrders';
import { RidesList, RideShow } from './rides';
import { EightAuthentication } from './authProvider';
import realtime from './realtime'
import enMessages from 'ra-language-english';
import { EightProvider } from './dataProvider';
import domainEnMessages from './i18n/en';
import EightLayout from './EightLayout';
import EightLogin from './EightLogin';
import Folder from '@material-ui/icons/Folder';
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
// import ListAlt from '@material-ui/icons/ListAlt'
import ListIcon from './ListIcon';

const messages = {...enMessages, ...domainEnMessages};
const i18nProvider = locale => messages;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHENTICATION_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
}

const dataProvider = EightProvider(firebaseConfig);
const realTimeSaga = realtime(dataProvider);
const authProvider = EightAuthentication(firebaseConfig);
const App = () => (
  <Admin loginPage={EightLogin} appLayout={EightLayout} authProvider={authProvider} dataProvider={dataProvider} customSagas={[realTimeSaga]} i18nProvider={i18nProvider}>
    <Resource name="orders" options={{label: "Current Orders"}} list={OrderList} show={OrderShow} icon={ListIcon} />
    <Resource name="pastorders" options={{label: "Past Orders"}} list={PastOrderList} show={OrderShow} icon={Folder} />
    <Resource name="rides" options={{label: "Rides"}} list={RidesList} show={RideShow} icon={MotorcycleIcon}/>
  </Admin>
);

export default App;
