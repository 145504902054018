import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'react-router-redux';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

const styles = theme => ({
    button: {
        color: "white",
        marginRight: "10px",
        '&:hover': {
            color: "white",
            backgroundColor: "rgba(255,255,255,0.1)",
        }
    }
});


class BackButton extends Component {
    
    handleClick = () => {
        this.props.push(this.props.basePath);
    }

    render() {
        var {classes} = this.props;
        return (
            <Fragment>
                <IconButton size="medium" onClick={this.handleClick} className={classes.button} >
                    <KeyboardArrowLeft />
                </IconButton>
            </Fragment>
        )
    }
}

export default connect(null, {push})(withStyles(styles)(BackButton));