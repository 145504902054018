import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { change, submit } from 'redux-form';
import { push } from 'react-router-redux';

import { fetchEnd, fetchStart, showNotification, SimpleForm, LongTextInput } from 'react-admin';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button'; 
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import firebase from 'firebase'

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        // margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        textTransform: "None"
    },
    confirmButton: {
        width: "150px",
        margin: "10px 10px 10px 10px",
        padding: "8px",
        textAlign: "center",
        backgroundColor: "#DA9A0B",
        color: "white",
        textTransform: "None",
        '&:hover': {
            backgroundColor: "#C48A02",
        }
    },
    cancelbutton: {
        width: "150px",
        margin: "10px 10px 10px 10px",
        padding: "8px",
        textAlign: "center",
        backgroundColor: "#CCCCCC",
        color: "#545454",
        textTransform: "None",
        '&:hover': {
            backgroundColor: "#BABABA",
        }
    }
}

class CancelButton extends Component {

    state = {
        error: false,
        showDialog: false
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleSaveClick = () => {
        const { submit } = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('cancel-order');
    };

    handleSubmit = values => {

        const {push, basePath, record, fetchStart, fetchEnd, showNotification, isLoading} = this.props;

        var body = {id: record.id, internalnotes: values.notes || null}

        if (isLoading) {
            return
        }

        // Dispatch an action letting react-admin know a API call is ongoing
        fetchStart();

        var functions = firebase.apps[0].functions(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_REGION)

        // Call firebase function to cancel the order
        functions.httpsCallable("cancelOrder")(body)
        .then((response) => {
            showNotification("eight.orders.cancel.ok");
            this.setState({showDialog: false});
            push(basePath);
        })
        .catch((error) => {
            console.log(error);
            showNotification("eight.orders.cancel.error", "error");
            this.setState({showDialog: false});
        })
        .finally(() => {
            values.notes = "";
            fetchEnd();
        })

    };

    render() {
        const { showDialog } = this.state;
        const { classes, record, isLoading } = this.props;
        return (!record || record.statusCode >= 60) ? null : (
            <Fragment>
                <Button variant="contained" onClick={this.handleClick} className={classes.cancelbutton} >Cancel Order</Button>
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Cancel Order"
                >
                    <DialogTitle classes={{"root": "dialog-title"}} >Do you really want to cancel this order?</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            // We override the redux-form name to avoid collision with the react-admin main form
                            form="cancel-order"
                            resource="orders"
                            // We override the redux-form onSubmit prop to handle the submission ourselves
                            onSubmit={this.handleSubmit}
                            // We want no toolbar at all as we have our modal actions
                            toolbar={null}
                        >
                            <LongTextInput
                                source="notes"
                            />
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions style={{justifyContent: "center"}}>
                        <div className={classes.root}>
                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    className={classes.confirmButton}
                                    onClick={this.handleSaveClick}
                                    disabled={isLoading}
                                >
                                Yes
                                </Button>
                                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            <div className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    className={classes.cancelbutton}
                                    onClick={this.handleCloseClick}
                                    disabled={isLoading}
                                >
                                No
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

// const mapStateToProps = state => ({
//     // record: state.admin.record
// });

const mapDispatchToProps = {
    push,
    change,
    fetchEnd,
    fetchStart,
    showNotification,
    submit,
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles)(CancelButton)
);
