import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles";

const DistanceField = ({ classes, source, unit = "Km", record = {}}) => {

    var distance = record[source] + " " + unit;

    return (
        <span className={classes.field}>{distance}</span>
    )
}

DistanceField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    unit: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles.listView)(DistanceField);