import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';

import firebase from 'firebase'

export const EightAuthentication = (firebaseConfig = {}, config = {}) => {

    const firebaseLoaded = () => new Promise(resolve => {
        firebase.auth().onAuthStateChanged(resolve);
    });

    return async (type, params) => {

        if (firebase.apps.length === 0) {
            firebase.initializeApp(firebaseConfig);
            firebase.firestore().settings({timestampsInSnapshots: true});
        }
        
        // called when the API returns an error
        if (type === AUTH_ERROR) {

            if (params.message.startsWith("eight.auth")) {
                firebase.auth().signOut();
            }

            return Promise.resolve();

        }

        // called when the user clicks on the logout button
        if (type === AUTH_LOGOUT) {
            return firebase.auth().signOut();
        }

        if (type === AUTH_CHECK || type === AUTH_GET_PERMISSIONS) {
            await firebaseLoaded()
            return firebase.auth().currentUser ? Promise.resolve() : Promise.reject();
        }

        // called when the user attempts to log in
        if (type === AUTH_LOGIN) {

            const { username, password, alreadySignedIn } = params;

            try {

                var auth = firebase.auth().currentUser;

                if (!auth || !alreadySignedIn) {

                    auth = await firebase.auth().signInWithEmailAndPassword(username, password)

                    return Promise.resolve();

                }

            }
            catch (error) {
                return Promise.reject("ra.auth.sign_in_error", error);
            }

        }

        return Promise.reject();

    };

};