import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles";

const AddressField = ({ classes, source, record = {}}) => {

    var address = record[source].street;

    if (record[source].door) {
        address = address + ", " + record[source].door;
    }

    return (
        <span className={classes.field}>{address}</span>
    )
}

AddressField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles.field)(AddressField);