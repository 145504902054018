import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles"

class ExternalLinkField extends Component {

    render() {

        const {classes, record, source} = this.props;
        var url = null;

        if (this.props.url && record[source])
            url = this.props.url + record[source];

        return url ? (
            <a className={classes.field} href={url} target="__blank" >{url}</a>
        ) : (<span className={classes.field}>-</span>);
    }
}

ExternalLinkField.propTypes = {
    label: PropTypes.string,
    url: PropTypes.string,
    record: PropTypes.object
};

export default withStyles(styles.listView)(ExternalLinkField);