// In createRealtimeSaga.js
import { GET_LIST, GET_ONE } from 'react-admin';
import realtimeSaga from 'ra-realtime';
import firebase from 'firebase'

const moment = require('moment-timezone');
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const observeRequest = dataProvider => (type, resource, params) => {

    // Filtering so that only orders are updated in real time
    if (resource !== 'orders' && resource !== 'rides') return;

    // Use your apollo client methods here or sockets or whatever else including the following very naive polling mechanism
    return {

        subscribe(observer) {

            let ordersList = {};
            // let intervalId = null;

            switch (resource) {

                case "orders":

                    switch (type) {
                        
                        case GET_LIST:
                        
                            // Every time that is an update it should request the data again
                            var unsubscribe_orders_listener = firebase.firestore().collection("orders").where("statusCode", "<", 60).onSnapshot(snapshot => {
                                dataProvider(type, resource, params)
                                .then(results => observer.next(results))
                                .catch(error => observer.error(error));
                            })

                            // Polling to set the time related alerts in orders
                            var intervalId = setInterval(() => {
                                dataProvider(type, resource, params)
                                .then(results => observer.next(results))
                                .catch(error => observer.error(error));
                            }, 60000); // 1 minute

                            break;

                        case GET_ONE:

                            var unsubscribe_order_listener = firebase.firestore().collection("orders").doc(params.id).onSnapshot(snapshot => {
                                dataProvider(type, resource, params)
                                .then(results => observer.next(results))
                                .catch(error => observer.error(error));
                            })

                            break;
                        
                        default:
                            console.log("Undocumented method in realtime");
                    }

                    break;

                case "rides":
                    
                    switch(type) {

                        case GET_LIST:
                            // Every time that is an update it should request the data again
                            var query = firebase.firestore().collection("rides")
                            var startDate = (params.filter.startDate && moment(params.filter.startDate).tz(TIMEZONE).startOf('day')) || moment().tz(TIMEZONE).subtract(1, "months").startOf('day');
                            var endDate = (params.filter.endDate && moment(params.filter.endDate).tz(TIMEZONE).endOf('day'));

                            if (startDate)
                                query = query.where("deliverAt", ">=", startDate.valueOf())

                            if (endDate) {
                                query = query.where("deliverAt", "<=", endDate.valueOf())
                            }

                            var unsubscribe_rides_listener = query.onSnapshot(snapshot => {
                                dataProvider(type, resource, params)
                                .then(results => observer.next(results))
                                .catch(error => observer.error(error));
                            })

                            break;

                        case GET_ONE:

                            var unsubscribe_ride_listener = firebase.firestore().collection("rides").doc(params.id).onSnapshot(snapshot => {
                                dataProvider(type, resource, params)
                                .then(results => observer.next(results))
                                .catch(error => observer.error(error));
                            })

                            console.log("GET ONE RIDE");

                            break;

                        default:
                            console.log("Undocumented method in realtime")
                    }

                    break;
                default:
            
            }

            const subscription = {
                unsubscribe() {
                    
                    if (ordersList !== null) {
                        // Initialize orders list
                        ordersList = null;
                        // Unsubscribe orders listener
                        if (unsubscribe_orders_listener) {
                            unsubscribe_orders_listener();
                        }
                        // Unsubscribe interval
                        if (intervalId) {
                            clearInterval(intervalId);
                        }
                        // Unsubscribe order listener
                        if (unsubscribe_order_listener) {
                            unsubscribe_order_listener();
                        }
                        // Unsubscribe rides listener
                        if (unsubscribe_rides_listener) {
                            unsubscribe_rides_listener();
                        }
                        // Unsubscribe rides listener
                        if (unsubscribe_ride_listener) {
                            unsubscribe_ride_listener();
                        }
                        // Notify the saga that we cleaned up everything
                        observer.complete();
                    }
                }
            };

            return subscription;
        },
    };

};

export default dataProvider => realtimeSaga(observeRequest(dataProvider));
