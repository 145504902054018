import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, DateInput, downloadCSV } from 'react-admin';
import DateTimeField from './DateTimeField';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles";
import StatusField from './StatusField'
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const moment = require('moment-timezone');
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const PastOrderFilter = (props) => {
    var lastmonth =  moment().tz(TIMEZONE).subtract(1, "months");
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
            <DateInput label="Start Date" source="startDate" defaultValue={lastmonth.valueOf()} />
            <DateInput label="End Date" source="endDate" />
        </Filter>
    )
}

const exporter = (orders) => {
    const ordersToExport = orders.map(order => {
        
        var address = order.userAddress.street;

        if (order.userAddress.door) {
            address += ", " + order.userAddress.door;
        }

        var orderToExport = {
            status: order.status,
            name: order.userName,
            phone: order.userPhone,
            address: address,
            deliver_at: moment(order.deliverAt).tz(TIMEZONE).format("DD/MM/YYYY, H:mm"),
            updated_at: moment(order.statusUpdatedAt).tz(TIMEZONE).format("DD/MM/YYYY, H:mm"),
            order_price: order.totalPrice - order.deliveryFee,
            delivery_fee: order.deliveryFee,
            final_price: order.totalPrice,
            vat: order.vat,
            notes: order.notes,
            id: order.id,
            ride: order.rideId
        }
        return orderToExport;
    });
    const csv = convertToCSV({
        data: ordersToExport,
        fields: ["status", "name", "phone", "address", "deliver_at", "updated_at", "order_price", "delivery_fee", "final_price", "vat", "notes", "id", "ride"]
    });
    downloadCSV(csv, "orders");
}

export const PastOrderList = withStyles(styles.listView)(({classes, ...props}) => (
    <List title="Past Orders" {...props} filters={<PastOrderFilter />} sort={{field: "statusUpdatedAt", order: "ASC"}} bulkActionButtons={false} exporter={exporter}>
        <Datagrid rowClick="show" classes={{headerCell: classes.header, row: classes.row}} >
            <TextField source="userName" label="Name" headerClassName={classes.paddingLeft} cellClassName={classes.paddingLeft} className={classes.boldCell} />
            <TextField source="userPhone" label="Phone" />
            <DateTimeField source="createdAt" label="Created" format="DD/MM/YYYY, H:mm" />
            <DateTimeField source="statusUpdatedAt" label="Updated" format="DD/MM/YYYY, H:mm"/>
            <StatusField source="status" label="Status" headerClassName={classes.boldHeader} className={classes.boldCell} />
        </Datagrid>
    </List>
));