import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles";

var moment = require('moment-timezone');

const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const DateTimeField = ({ classes, source, format = null, record = {}, small=true }) => {

    var value = moment(record[source]).tz(TIMEZONE).format(format);
    var prefix = ""
    
    if (source === "deliverAt" && record.asap) {
        prefix = small ? "ASAP - Up to " : "As soon as possible - Up to "
    }

    return (
        <span className={classes.field}>{prefix + value}</span>
    )
}

DateTimeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    format: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles.listView)(DateTimeField);