import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles"

const RideStatusField = ({ classes, source, record = {}}) => {

    var status = null;

    switch(record[source]) {
        case "STARTED": status = "Started"; break;
        case "SUCCESS": status = "Success"; break;
        case "FAILED": status = "Failed"; break;
        case "CANCELLED_BY_ADMIN": status = "Cancelled by Admin"; break;
        default: status = null;
    }

    return (
        <span className={classes.boldField}>{status}</span>
    )
}

RideStatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles.listView)(RideStatusField);