import firebase from 'firebase';
import sortBy from 'sort-by';
import { GET_LIST, GET_ONE } from 'react-admin';
import { objectEquals, searchList, paginator } from './utils';

const moment = require('moment-timezone');

const TOOKAN_URL = process.env.REACT_APP_TOOKAN_URL;
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const BaseConfiguration = {
    initialQueryTimeout: 100000
}


const FirestoreDecorator = f => async (...args) => {

    try {
        return await f(...args)
    }
    catch (error) {

        console.error(error);

        if (error.code === "permission-denied")
            throw new Error("eight.auth.required");
        
        throw new Error(error)

    }

}

export const EightProvider = (firebaseConfig = {}, options = {}) => {

    options = Object.assign({}, BaseConfiguration, options);
    
    const resourcesData = {}
    const resourcesParams = {}
    const resourcesFilterFields = {
        "orders": ["id", "userName", "userPhone"],
        "pastorders": ["id", "userName", "userPhone"],
        "rides": ["riderName", "status"]
    }
    
    // initialize firebase
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
        // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        firebase.firestore().settings({timestampsInSnapshots: true});
    }

    const getList = async (params, resourceName) => {

        switch (resourceName) {
            case "orders": return getOrdersList(params, resourceName);
            case "pastorders": return getPastOrdersList(params, resourceName);
            case "rides": return getRidesList(params, resourceName);
            default: return {data:[], total: 0};
        }

    }

    const isToFetch = (params, resourceName, resourcesData, resourcesParams) => {

        var data = resourcesData[resourceName];
        var oldParams = resourcesParams[resourceName];

        // if there is no data and no attempt to fetch it should fetch data
        if (data === null || data === undefined) {
            return true;
        }

        // if the parameters remain the same from the last request it means that it is a refresh
        if (objectEquals(params, oldParams)) {
            return true
        }

        // if there is no parameters should return false
        if (!params) {
            return false;
        }

        // if the time interval changes should fetch the data
        if ((params.filter.startDate !== oldParams.filter.startDate) || (params.filter.endDate !== oldParams.filter.endDate)) {
            return true;
        }

        return false;

    }

    const getOrdersList = async (params, resource, avoidFetch=false) => {

        if (!avoidFetch && isToFetch(params, resource, resourcesData, resourcesParams)) {

            var snapshot = await FirestoreDecorator(currentOrdersListQuery)();

            resourcesData[resource] = {}

            for (const doc of snapshot.docs) {
                const data = formatOrder(doc.data());
                resourcesData[resource][data.id] = data;
            }
            
        }

        var resourceData = resourcesData[resource];
        var filterableFields = resourcesFilterFields[resource];

        if (params)
            resourcesParams[resource] = params;

        params = resourcesParams[resource]
            
        let values = Object.values(resourceData);

        values = searchList(values, filterableFields, params.filter.q)
        
        if (params.sort) {
            values.sort(sortBy(`${params.sort.order === 'ASC' ? '-' : ''}${params.sort.field}`));
        }

        values = values.map(setOrderAlert);

        const data = values;
        const total = values.length;

        return { data, total };

    }

    const currentOrdersListQuery = async () => {
        return await firebase.firestore().collection("orders").where("statusCode", "<", 60).get()
    }

    const setOrderAlert = (resource) => {

        if (resource.status === "NEW") {
            resource.alert = {"type": "info", "message": "New order"}
        }

        if (moment(resource.deliverAt).utc() < moment().utc()) {
            resource.alert = {"type": "warning", "message": "Not delivered on time"}
        }

        if (resource.status === "READY_FOR_DELIVERY" && (moment(resource.statusUpdatedAt) < moment.utc().subtract(5, "minutes"))) {
            resource.alert = {"type": "warning", "message": "Without Driver Pickup after 5min", "link": TOOKAN_URL}
        }

        if (resource.tookan && resource.tookan.status && (resource.tookan.status === 3 || resource.tookan.status === 9)) {
            resource.alert = {"type": "error", "message": "Delivery platform error", "link": TOOKAN_URL}
        }

        if (!moment().tz(TIMEZONE).isSame(moment(resource.deliverAt).tz(TIMEZONE), "day")) {
            resource.alert = {"type": "error", "message": "Expired. Past Order!"}
        }

        return resource;

    }

    const formatOrder = (data) => {

        for (var key in data) {
            if (data[key] === null || data[key] === undefined || (typeof data[key] == "string" && data[key] === "")) {
                data[key] = "-";
            }
        }
        
        return data;
    
    }

    const getPastOrdersList = async (params, resourceName) => {

        // obtain data from firebase according to some conditions
        if (isToFetch(params, resourceName, resourcesData, resourcesParams)) {

            // get start and end date
            var startDate = (params.filter.startDate && moment(params.filter.startDate).tz(TIMEZONE).startOf('day')) || moment().tz(TIMEZONE).subtract(1, "months").startOf('day');
            var endDate = (params.filter.endDate && moment(params.filter.endDate).tz(TIMEZONE).endOf('day'));

            // var query = await firebase.firestore().collection("orders")

            // if (startDate) {
            //     query = query.where("createdAt", ">=", startDate.valueOf())
            // }

            // if (endDate) {
            //     query = query.where("createdAt", "<=", endDate.valueOf())
            // }

            // var snapshots = await query.get();
            var snapshots = await FirestoreDecorator(pastOrdersListQuery)(startDate, endDate);

            resourcesData[resourceName] = {};

            for (const doc of snapshots.docs) {

                const data = doc.data();

                if (data.statusCode >= 60) {
                    resourcesData[resourceName][data.id] = data;
                }

            }

        }

        resourcesParams[resourceName] = params;

        var values = Object.values(resourcesData[resourceName]);
        
        values = searchList(values, resourcesFilterFields[resourceName], params.filter.q);

        if (params.sort) {
            values.sort(sortBy(`${params.sort.order === 'ASC' ? '-' : ''}${params.sort.field}`));
        }
    
        return paginator(params.pagination, values);

    }

    const pastOrdersListQuery = async (startDate, endDate) => {

        var query = await firebase.firestore().collection("orders")

        if (startDate) {
            query = query.where("createdAt", ">=", startDate.valueOf())
        }

        if (endDate) {
            query = query.where("createdAt", "<=", endDate.valueOf())
        }

        return await query.get();

    }

    const getRidesList = async (params, resourceName) => {
        
        // obtain data from firebase according to some conditions
        if (isToFetch(params, resourceName, resourcesData, resourcesParams)) {

            // get start and end date
            var startDate = (params.filter.startDate && moment(params.filter.startDate).tz(TIMEZONE).startOf('day')) || moment().tz(TIMEZONE).subtract(1, "months").startOf('day');
            var endDate = (params.filter.endDate && moment(params.filter.endDate).tz(TIMEZONE).endOf('day'));

            var snapshots = await FirestoreDecorator(ridesListQuery)(startDate, endDate);

            resourcesData[resourceName] = {};

            for (const doc of snapshots.docs) {
                const data = doc.data();
                resourcesData[resourceName][data.id] = data;
            }

        }

        resourcesParams[resourceName] = params;

        var values = Object.values(resourcesData[resourceName]);
        
        values = searchList(values, resourcesFilterFields[resourceName], params.filter.q);

        if (params.sort) {
            values.sort(sortBy(`${params.sort.order === 'ASC' ? '-' : ''}${params.sort.field}`));
        }
    
        return paginator(params.pagination, values);
        
    }

    const ridesListQuery = async (startDate, endDate) => {

        var query = await firebase.firestore().collection("rides")

        if (startDate) {
            query = query.where("deliverAt", ">=", startDate.valueOf())
        }

        if (endDate) {
            query = query.where("deliverAt", "<=", endDate.valueOf())
        }

        return await query.get();

    }

    const getOne = async (params, resource) => {

        switch (resource) {
            case "orders":
            case "pastorders": return getOrder(params, resource);
            case "rides": return getRide(params, resource);
            default: throw new Error("eight.not_implemented");
        }

    }

    const getOrder = async (params, resource) => {

        // var order = await firebase.firestore().collection("orders").doc(params.id).get();
        var order = await FirestoreDecorator(orderQuery)(params.id);

        if (!order.exists) {
            throw new Error("eight.orders.not_found");
        }

        order = formatOrder(order.data());
        order.items = [];

        // var snapshot = await firebase.firestore().collection("orderItems/" + params.id + "/items").get()
        var snapshot = await FirestoreDecorator(orderItemsQuery)(params.id);

        snapshot.docs.forEach(doc => {
            order.items.push(doc.data());
        })

        // "type": type,
		// 	"subType": subtype,
		// 	"itemId": data.id,
		// 	"name": data.name,
		// 	"quantity": item.quantity,
		// 	"price": ((data.price * 100) * item.quantity) / 100
        order.items.push({name: "Delivery Fee", quantity: "-", price: (order.deliveryFee || 0)})
        order.items.push({name: "Total", quantity: "-", price: order.totalPrice})

        if (resource === "orders") {
            order = setOrderAlert(order);
        }

        return {data: order};

    }

    const orderQuery = async (id) => {
        return await firebase.firestore().collection("orders").doc(id).get();
    }

    const orderItemsQuery = async (id) => {
        return await firebase.firestore().collection("orderItems/" + id + "/items").get()
    }

    const getRide = async (params, resource) => {

        var ride = await FirestoreDecorator(rideQuery)(params.id);

        if (!ride.exists)
            throw new Error("eight.rides.not_found")

        return {data: ride.data()};

    }

    const rideQuery = async (id) => {
        return await firebase.firestore().collection("rides").doc(id).get();
    }
    
  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resourceName Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */

    // return async (type, resourceName, params) => {
    return (type, resourceName, params) => {

        switch (type) {
            case GET_LIST:
                return getList(params, resourceName);    
            case GET_ONE:
                return getOne(params, resourceName);
            default: throw new Error("eight.not_implemented")
        }
        
    }
}