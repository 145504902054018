export const objectEquals = (x, y) => {

    if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) { return false; }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) { return x === y; }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) { return x === y; }
    if (x === y || x.valueOf() === y.valueOf()) { return true; }
    if (Array.isArray(x) && x.length !== y.length) { return false; }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) { return false; }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) { return false; }
    if (!(y instanceof Object)) { return false; }

    // recursive object equality check
    var p = Object.keys(x);
    return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
        p.every(function (i) { return objectEquals(x[i], y[i]); });

}

// export const setObject 

export const searchList = (values, filterableFields, search) => {
    
    if (!search) {
        return values;
    } 

    values = values.filter((value) => {
        var contains = false;
        filterableFields.forEach(field => {
            if (value[field].toLowerCase().includes(search.toLowerCase())) {
                contains = true;
            }
        });
        return contains;
    });

    return values;

}

export const paginator = (pagination, values) => {
    const { page, perPage } = pagination;
    const _start = (page - 1) * perPage;
    const _end = page * perPage;
    const data = values ? values.slice(_start, _end) : [];
    const total = values ? values.length : 0;
    return {data, total};
}

export const updateObjectFields = (obj, updatedFields) => {

    for (var key in updatedFields) {
        obj[key] = updatedFields[key]
    }

    return obj

}