import React from 'react';
import { List, Datagrid, TextField, NumberField, TextInput, DateInput, Filter, Show, SimpleShowLayout, downloadCSV } from 'react-admin';
import DateTimeField from './DateTimeField';
import DistanceField from './DistanceField';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles";
import BackButton from "./BackButton";
import RideStatusField from "./RideStatusField";
import LinkField from "./LinkField";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const moment = require('moment-timezone');
const TIMEZONE = process.env.REACT_APP_TIMEZONE;

const RidesFilter = (props) => {
    var lastmonth =  moment().tz(TIMEZONE).subtract(1, "months");
    return (
        <Filter {...props}>
            <TextInput label="Search" source="q" alwaysOn/>
            <DateInput label="Start Date" source="startDate" defaultValue={lastmonth.valueOf()} />
            <DateInput label="End Date" source="endDate" />
        </Filter>
    )
}

const exporter = rides => {
    const ridesForExport = rides.map(ride => {
        var rideForExport = {
            status: ride.status,
            rider: ride.riderName,
            rider_phone: ride.riderPhone,
            deliver_at: moment(ride.deliverAt).tz(TIMEZONE).format("DD/MM/YYYY, H:mm"),
            cost: ride.cost,
            pickup_distance: ride.pickupDistance,
            delivery_distance: ride.deliveryDistance,
            total_distance: ride.distance,
            customer: ride.customerName,
            updated_at: moment(ride.updatedAt).tz(TIMEZONE).format("DD/MM/YYYY, H:mm"),
            id: ride.id,
            order: ride.orderId
        }
        return rideForExport;
    });
    const csv = convertToCSV({
        data: ridesForExport,
        fields: ["status", "rider", "rider_phone", "deliver_at", "cost", "pickup_distance", "delivery_distance", "total_distance", "customer", "updated_at", "id", "order"]
    });
    downloadCSV(csv, "rides")
}

export const RidesList = withStyles(styles.listView)(({classes, ...props}) => (
    <List title="Rides" {...props} filters={<RidesFilter />} sort={{field: "deliverAt", order: "ASC"}} bulkActionButtons={false} exporter={exporter}>
        <Datagrid rowClick="show" classes={{headerCell: classes.headers, row: classes.row}}>
            <TextField label="Name" source="riderName" headerClassName={classes.paddingLeft} cellClassName={classes.paddingLeft} className={classes.boldCell} />
            <DateTimeField source="deliverAt" label="Deliver" format="DD/MM/YYYY, H:mm" />
            <TextField source="customerName" label="Customer" />
            <DistanceField source="distance" label="Distance" />
            <NumberField source="cost" label="Cost" options={{style: "currency", currency: "EUR"}} headerClassName={classes.alignLeft} cellClassName={classes.alignLeft} />
            <RideStatusField source="status" label="Status" headerClassName={classes.boldHeader} className={classes.boldCell} />
        </Datagrid>
    </List>
));

const RideTitle = (props) => {

    var formattedDate = moment(props.record.deliverAt).tz(TIMEZONE).format("DD/MM/YYYY, H:mm")
    
    return (
        <span>
            <BackButton {...props} />
            {props.record.riderName + " - " + formattedDate}
        </span>
    )
}

export const RideShow = withStyles(styles.showView)(({classes, ...props}) => {

    return (
        <Show {...props} title={<RideTitle basePath={props.basePath} resource={props.resource} />} >
            <SimpleShowLayout className={classes.container}>
                <RideStatusField className="show-field-bold" source="status" label="Status" addLabel={true} />
                <TextField className="show-field" label="Rider" source="riderName" />
                <TextField className="show-field" label="Rider Phone" source="riderPhone" />
                <DateTimeField className="show-field" label="Deliver" source="deliverAt" format="DD/MM/YYYY, H:mm" addLabel={true} small={false} />
                <NumberField className="show-field" source="cost" options={{style: "currency", currency: "EUR"}} />
                <DistanceField className="show-field" label="Pickup Distance" source="pickupDistance" addLabel={true} />
                <DistanceField className="show-field" label="Delivery Distance" source="deliveryDistance" addLabel={true} />
                <DistanceField className="show-field" label="Total Distance" source="distance" addLabel={true} />
                <TextField className="show-field" label="Customer" source="customerName" />
                <DateTimeField className="show-field" label="Updated" source="updatedAt" format="DD/MM/YYYY, H:mm" addLabel={true} small={false} />
                <TextField className="show-field" label="identifier" source="id" />
                <LinkField className="show-field" label="Order" source="orderId" reference={"orders"} addLabel={true}/>
            </SimpleShowLayout>
        </Show>
    )

})