import React from 'react';
// import Tooltip from '@material-ui/core/Tooltip';
// import PlaceIcon from '@material-ui/icons/Place';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const TOOKAN_URL = "https://app.tookanapp.com/#/app/dashboard";

const styles = theme => ({
    tracking: {
        color: "black",
        '&:visited': {
            color: "black",
        },
        '&:hover': {
            color: "black",
        }
    },
    trackingButton: {
        backgroundColor: "black",
        color: "white",
        textTransform: "None",
        margin: "10px 10px 10px 10px",
        '&:visited': {
            backgroundColor: "black",
        },
        '&:hover': {
            backgroundColor: "black",
        }
    }
});

const Tracking = ({ classes, record = {} }) => {

    if (!record.tookan || !record.tookan.pickup_tracking_link || !record.tookan.delivery_tracing_link)
        return null;

    var link = TOOKAN_URL;

    if (record.statusCode >= 40 && record.statusCode < 50)
        link = record.tookan.pickup_tracking_link;

    if (record.statusCode >= 50 && record.statusCode < 60)
        link = record.tookan.delivery_tracing_link;

    return (
        <Button variant="contained" className={classes.trackingButton} href={link} target="_blank">
            Track Order
        </Button>
    )
    // return (
    //     <Tooltip title={"Tracking"}>
    //         <a href={link} target="_blank" rel="noopener noreferrer" className={classes.tracking}>
    //             <PlaceIcon style={{fontSize: 40, verticalAlign: "middle", marginRight: "10px", paddingBottom: "1px"}} />
    //         </a>
    //     </Tooltip>
    // )
    
}

Tracking.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

export default withStyles(styles)(Tracking);