import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from "./styles"
import { push } from 'react-router-redux';

class LinkField extends Component {

    handleClick = (e) => {
        e.preventDefault();
        this.props.push(this.createUrl())
    }

    createUrl = () => {
        const {source, record, reference} = this.props;
        return "/" + reference + "/" + record[source] + "/show"
    }

    render() {

        const {classes, source, record} = this.props;
        
        return record[source] ? (
            <a className={classes.field} href={this.createUrl()} onClick={this.handleClick}>{record[source] || "-"}</a>
        ) : (<span className={classes.field}>-</span>);
    }
}

LinkField.propTypes = {
    push: PropTypes.func,
    label: PropTypes.string,
    record: PropTypes.object,
    reference: PropTypes.string,
    source: PropTypes.string.isRequired,
};

export default connect(null, {
    push
})(withStyles(styles.listView)(LinkField));
