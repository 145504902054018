export const styles = {
    showView: {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        item: {
            width: "350px",
            " label": {
                fontSize: "22px",
                fontWeight: "bold",
                color: "black"
            }
        }
    },
    listView: {
        header: {
            color: "rgba(0,0,0,0.8)",
            fontSize: "13px",
            fontWeight: "400"
        },
        boldHeader: {
            fontWeight: "bold"
        },
        row: {
            borderBottom: "1px solid rgba(0,0,0,0.05)"
        },
        boldCell: {
            fontWeight: "bold"
        },
        alignLeft: {
            textAlign: "left"
        },
        paddingLeft: {
            paddingLeft: "20px"
        },
        field: {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "0.875rem",
            fontWeight: 400,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.46429em"
        },
        boldField: {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "0.875rem",
            fontWeight: "bold",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            lineHeight: "1.46429em"
        }
    }
}