import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    alertlink: {
        color: "black",
        '&:visited': {
            color: "black",
        },
        '&:hover': {
            color: "black",
        }
    },
    icon: {
        fontSize: "20px",
        padding: "5px 0px 0px 20px",
    },
    iconBig: {
        padding: "15px 10px 16px 0px",
        fontSize: "36px",
        verticalAlign: "middle"
    },
    alertMessage: {

    },
    error: {
        color: "rgba(236,15,5,1)"
    },
    warning: {
        color: "#C48A02"
    },
    info: {
        color: "rgba(0,100,25,1)"
    }
});

export const AlertField = withStyles(styles)(({ classes, record = {} }) => {

    if (!record.alert)
        return null;

    var link = (!record.alert.link) ? {} : {
        className: classes.alertlink,
        href: record.alert.link,
        target: "_blank"
    }

    if (record.alert.type === "warning") 
        return (
            <Tooltip title={record.alert.message}>
                <a {...link}>
                    <WarningIcon className={[classes.icon, classes.warning].join(' ')} />
                </a>
            </Tooltip>
        )

    if (record.alert.type === "error")
        return (
            <Tooltip title={record.alert.message}>
                <a {...link}>
                    <ErrorIcon className={[classes.icon, classes.error].join(' ')} />
                </a>
            </Tooltip>
        )

    if (record.alert.type === "info")
        return (
            <Tooltip title={record.alert.message}>
                <a {...link}>
                    <InfoIcon className={[classes.icon, classes.info].join(' ')} />
                </a>
            </Tooltip>
        )

    return null;

})

AlertField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};

export const DetailedAlertField = withStyles(styles)(({ classes, record = {} }) => {
    
    if (!record.alert)
        return null;

    var link = (!record.alert.link) ? {} : {
        className: classes.alertlink,
        href: record.alert.link,
        target: "_blank"
    }

    if (record.alert.type === "info")
        return (
            <a {...link}>
                <InfoIcon className={[classes.iconBig, classes.info].join(' ')} />{record.alert.message}
            </a>
        )

    if (record.alert.type === "warning")
        return (
            <a {...link}>
                <WarningIcon className={[classes.iconBig, classes.warning].join(' ')} />{record.alert.message}
            </a>
        )

    if (record.alert.type === "error")
        return (
            <a {...link}>
                <ErrorIcon className={[classes.iconBig, classes.error].join(' ')} />{record.alert.message}
            </a>
        )   

    return null;

})

DetailedAlertField.propTypes = {
    record: PropTypes.object
}