import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import firebase from 'firebase'

// import AlphaKCircleOutline from '@material-ui/icons/alphaKCircleOutline'

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        // margin: theme.spacing.unit,
        position: 'relative',
    },
    button: {
        width: "120px",
        margin: "10px 0px 10px 0px",
        padding: "8px",
        textAlign: "center",
        backgroundColor: "#DA9A0B",
        color: "white",
        textTransform: "None",
        '&:hover': {
            backgroundColor: "#C48A02",
        }
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}

class ChangeStatusButton extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
    }

    handleClick = (e) => {

        e.stopPropagation();

        const {record, showNotification} = this.props;
        var new_status = ""

        var isLoading = this.state.loading;

        if (isLoading) {
            return
        }

        switch (record.status) {
            case "NEW": new_status = "SENT_TO_KITCHEN"; break;
            case "SENT_TO_KITCHEN": new_status = "READY_FOR_DELIVERY"; break;
            case "WAITING_DRIVER": new_status = "DELIVERING"; break;
            default: showNotification('eight.orders.status_update.invalid', 'warning');
        }

        this.setState({loading: true})

        var functions = firebase.apps[0].functions(process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_REGION)
        
        functions.httpsCallable("changeOrderStatus")({"id": record.id, "status": new_status})
        .then((response) => {
            showNotification("eight.orders.status_update.ok");
        })
        .catch((error) => {
            console.log(error)
            showNotification("eight.orders.status_update.error", "error");
        })
        .finally(() => {
            this.setState({loading: false})
        });

    }

    get_action = (status) => {
        switch (status) {
            case "NEW": return "Send to Kitchen";
            case "SENT_TO_KITCHEN": return "Order Ready";
            case "WAITING_DRIVER": return "Picked by Driver";
            default: return null;
        }
    }

    render() {

        var {classes, record} = this.props;
        var action = this.get_action(record && record.status);
        var isLoading = this.state.loading;

        if (!action)
            return null;

        return (
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Button variant="contained" className={classes.button} onClick={this.handleClick} disabled={isLoading}>
                        {action}
                    </Button>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </div>
        )

    }

}

ChangeStatusButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
}

// const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

export default connect(null, {
    showNotification
})(withStyles(styles)(ChangeStatusButton));