import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

// const styles = {
//     field : {
//         color: "rgba(0, 0, 0, 0.87)",
//         fontSize: "0.875rem",
//         fontWeight: "bold",
//         fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//         lineHeight: "1.46429em"
//     }
// }

const StatusField = ({ classes, source, record = {}}) => {

    var status = null;

    switch(record[source]) {
        case "NEW": status = "New"; break;
        case "SENT_TO_KITCHEN": status = "Sent to Kitchen"; break;
        case "READY_FOR_DELIVERY": status = "Ready for Pickup"; break;
        case "WAITING_DRIVER": status = "Incoming Driver"; break;
        case "DELIVERING": status = "In Delivery"; break;
        case "DELIVERED": status = "Delivered"; break;
        case "CANCELLED": status = "Cancelled"; break;
        default: status = null;
    }

    return (
        <span className={classes.boldField}>{status}</span>
    )
}

StatusField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default withStyles(styles.listView)(StatusField);